import React, { lazy, Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
const Home = lazy(() => import("@/pages/home/index.jsx"));
const ToUpPage = lazy(() => import("@/pages/toUpPage/index.jsx"));
const UserTerms = lazy(() => import("@/pages/userterms"));
const PrivacyPolicy = lazy(() => import("@/pages/privacypolicy"));
const FindUs = lazy(() => import("@/pages/findus"));
const Success = lazy(() => import("@/pages/success"));
const Error = lazy(() => import("@/pages/error"));
const Router = () => {
  return (
    <Suspense>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/to-up-page" component={ToUpPage} />
        <Route exact path="/userterms" component={UserTerms} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} key={4} />
        <Route exact path="/findus" component={FindUs} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/error" component={Error} />
      </Switch>
    </Suspense>
  );
};
export default withRouter(Router);
